<template>
    <div class="about">
        <section id="cms-content" class="cms-despre-noi">
            <div class="container">
                <div class="cms-content" v-html="$t('cookie-policy.html')"></div>
            </div>
        </section>

        <section id="becomepartner">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 d-flex">
                        <div id="partner-text">
                            <h3>{{ $t('home.partner.title') }}</h3>
                            <p>{{ $t('home.partner.content') }}</p>
                            <a href="https://shop.koff.ro/" target=”_blank” class="btn btn-blue ripple">{{ $t('home.partner.register-button') }}</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div id="partner-img">
                            <img src="../assets/img/img-partners.jpg" class="img-responsive" alt="parteneri koff">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped>
    @import '../assets/scss/cms.scss';

</style>
